import { metrics, ValueType } from '@opentelemetry/api';

import { appName } from '../constants/setup';
import { Event } from '../types/event';

const DEFAULT_GC_DURATION_BUCKETS = [
  0.002, 0.004, 0.008, 0.016, 0.032, 0.064, 0.128, 0.256, 0.512, 1.02, 2.05, 4.1, 8.2, 16.4, 32.8,
];

const meter = () => metrics.getMeter(`${appName}-meter`);

const getMeterCarrier = (
  restDuration: number,
  hasFetchSloError: boolean,
  hasFetchAggregateError: boolean
): {
  restDuration: number;
  hasFetchSloError: boolean;
  hasFetchAggregateError: boolean;
} => {
  return {
    restDuration,
    hasFetchSloError,
    hasFetchAggregateError,
  };
};

const getEventsDuration = (events: Event[]): number => {
  if (events.length === 0) return 0;
  return events.map((event) => event.duration || 0).reduce<number>((acc, curr) => acc + curr, 0);
};

const getExtentsDuration = (events: Event[]): number => {
  if (events.length === 0) return 0;
  const minStartTime = Math.min(...events.map((event) => event.startTime));
  const maxEndTime = Math.max(...events.map((event) => event.endTime));
  return maxEndTime - minStartTime;
};

const measureDuration = async <T>(fn: () => Promise<T>): Promise<{ result: T; duration: number }> => {
  const startTime = performance.now();
  const result = await fn();
  const duration = performance.now() - startTime;
  return { result, duration };
};

const getSLOErrorsFromViewDefinition = (data: { viewDefinitionResults: any; themeTokens: any; cartData: any }) => {
  return [data.viewDefinitionResults === null, data.themeTokens === null, data.cartData === null].reduce(
    (acc, curr) => acc + (curr ? 1 : 0),
    0
  );
};

const getOpTotalCounter = (pageKey: string) =>
  meter().createCounter(`${pageKey}_op_total`, {
    description: 'Number of time page is accessed by the client (cumulative)',
    valueType: ValueType.INT,
  });

const getOpErrorsCounter = (pageKey: string) =>
  meter().createCounter(`${pageKey}_op_errors`, {
    description: 'Number of times page access yield error(s) (cumulative)',
    valueType: ValueType.INT,
  });

const getOpSloErrorsCounter = (pageKey: string) =>
  meter().createCounter(`${pageKey}_op_slo_errors`, {
    description: 'Number of times a route breached the SLO',
    valueType: ValueType.INT,
  });

const getOpAggregateErrorsCounter = (pageKey: string) =>
  meter().createCounter(`${pageKey}_op_aggregate_errors`, {
    description: 'Number of times either an error or SLO breach occurred during page load',
    valueType: ValueType.INT,
  });

const getOpNetworkDurationHistogram = (pageKey: string) =>
  meter().createHistogram(`${pageKey}_op_network_duration`, {
    description: 'Total latency of REST calls for page (cumulative) ',
    valueType: ValueType.DOUBLE,
    advice: {
      explicitBucketBoundaries: DEFAULT_GC_DURATION_BUCKETS,
    },
  });
const getOpDurationHistogram = (pageKey: string) =>
  meter().createHistogram(`${pageKey}_op_duration`, {
    description: 'Total time to render complete of the page (cumulative) ',
    valueType: ValueType.DOUBLE,
    advice: {
      explicitBucketBoundaries: DEFAULT_GC_DURATION_BUCKETS,
    },
  });
export {
  meter,
  getMeterCarrier,
  getEventsDuration,
  getExtentsDuration,
  measureDuration,
  getSLOErrorsFromViewDefinition,
  getOpDurationHistogram,
  getOpNetworkDurationHistogram,
  getOpErrorsCounter,
  getOpTotalCounter,
  getOpSloErrorsCounter,
  getOpAggregateErrorsCounter,
};
