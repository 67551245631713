import { Attributes } from '@opentelemetry/api';
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { FRONTEND } from 'Data/constants';

const isNodeRuntime = !!process.env.NEXT_RUNTIME || (typeof process === 'object' && process.release?.name === 'node');
const isJestRuntime = process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === 'test';
const isOtelBypass = typeof window !== 'undefined' && window.location.search.includes('disable-otel');
const isOtelEnabled = !isOtelBypass && process.env.NEXT_PUBLIC_OTEL_ENABLED === 'true';
const isOtelLogging = process.env.NEXT_PUBLIC_OTEL_LOGGING === 'true';

const tracesUrl = process.env.NEXT_PUBLIC_OTEL_TRACES_URL || 'invalid-url';
const metricsUrl = process.env.NEXT_PUBLIC_OTEL_METRICS_URL || 'invalid-url';
const appName = process.env.NEXT_PUBLIC_OTEL_CLIENT_NAME || 'invalid-app-name';
const tracesSampleRatio = Number(process.env.NEXT_PUBLIC_OTEL_TRACES_RATIO ?? 1);
const metricsIntervalMs = Number(process.env.NEXT_PUBLIC_OTEL_METRICS_INTERVAL_MS ?? 120000);
const clientType = process.env.NEXT_PUBLIC_OTEL_CLIENT_TYPE || 'default-client-type';
const criticalPathsSampled = process.env.NEXT_PUBLIC_OTEL_CRITICAL_PATHS_SAMPLED || 'default-critical-paths-sampled';
const apiFetchSloThreshold: number = Number(process.env.NEXT_PUBLIC_OTEL_API_REQUEST_SLO_THRESHOLD) || 5 * 1000;

const headers = {
  'Content-Type': 'application/json',
};

const resourceAttributes: Attributes = {
  [ATTR_SERVICE_NAME]: appName,
  'ld.lob': process.env.NEXT_PUBLIC_OTEL_LOB,
  'ld.banner': process.env.NEXT_PUBLIC_OTEL_BANNER,
  'ld.client.version': process.env.NEXT_PUBLIC_OTEL_CLIENT_VERSION,
};

export {
  isNodeRuntime,
  isJestRuntime,
  isOtelEnabled,
  isOtelLogging,
  tracesUrl,
  metricsUrl,
  appName,
  tracesSampleRatio,
  metricsIntervalMs,
  resourceAttributes,
  clientType,
  criticalPathsSampled,
  headers,
  apiFetchSloThreshold,
};
